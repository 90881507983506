import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";

import { PageLoader } from "components/loader";

import gqlMutations from "api/gql-mutations";
import logger from "lib/logger";
import { getUserEmail } from "lib/auth";

import { isTestMode } from "lib/utils";
import styles from "styles/Home.module.scss";
import { portfolioClient } from "../api/apollo-client";
import { isAuthenticated } from "utils/auth";

export default function Home() {
  const [testEmail, setTestEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const [login] = useMutation(gqlMutations.LOGIN, { client: portfolioClient });
  const [testLogin] = useMutation(gqlMutations.TEST_LOGIN);

  const onSuccess = async (credentialResponse: any) => {
    setErrorMessage("");

    if (credentialResponse.credential) {
      setLoading(true);

      let response: any;

      try {
        response = await login({
          variables: {
            authData: {
              value: credentialResponse.credential,
              type: "GOOGLE_TOKEN",
            },
          },
        });
      } catch (error: any) {
        setErrorMessage("Authentication failed. Please try later");
        logger.error("Authentication failed. Failed request to backend", {
          error,
        });
        setLoading(false);

        return;
      }

      if (response?.data?.resolveAuth?.success) {
        logger.debug("Successful login.");

        localStorage.setItem("authToken", response.data.resolveAuth?.token);

        datadogRum.startSessionReplayRecording();

        const userEmail = getUserEmail();
        datadogRum.setUser({ email: userEmail });

        router.push("/home");
        setLoading(false);
      } else {
        setErrorMessage("Authentication failed. Please try later");
        logger.error("Authentication failed. No success response from backend");
      }
    } else {
      setErrorMessage("Authentication failed. Please try later");
      logger.error("Login page. No credentials provided in Google response");
    }

    setLoading(false);
  };

  const onTestLogin = async () => {
    const response = await testLogin({ variables: { email: testEmail } });

    if (response?.data?.testLogin?.success) {
      localStorage.setItem("authToken", response.data.testLogin?.token);
      router.push("/home");
    }
  };

  const onError = () => {
    setErrorMessage("Authentication failed. Please try later");
    logger.error("Login page. Error response from Google");
    setLoading(false);
  };

  if (isTestMode()) {
    return (
      <div>
        <input
          id="testEmail"
          type="text"
          onChange={(e) => setTestEmail(e.target.value)}
        />
        <button id="testLogin" onClick={onTestLogin}>
          Login
        </button>
      </div>
    );
  }

  if (isAuthenticated()) {
    router.push("/home");
  }

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.authPanel}>
          <div className={styles.title}>
            Welcome to <br />
            General Catalyst <br />
            Data Manager
          </div>
          <GoogleLogin onSuccess={onSuccess} onError={onError} />
          <div display-if={errorMessage} className={styles.errorMessage}>
            {errorMessage}
          </div>
        </div>
      </main>
      {loading && <PageLoader />}
    </div>
  );
}
