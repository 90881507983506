import Image from "next/image";

import styles from "./loader.module.scss";

const PageLoader = () => {
  return (
    <div className={styles.pageHover}>
      <Image src="/loader.svg" width={80} height={80} alt="Loading" />
    </div>
  );
};

export default PageLoader;
