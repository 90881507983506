import Image from "next/image";

import styles from "./loader.module.scss";

const ComponentLoader = () => {
  return (
    <div className={styles.componentHover}>
      <Image src="/loader.svg" width={40} height={40} alt="Loading" />
    </div>
  );
};

export default ComponentLoader;
